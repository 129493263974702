input[type=checkbox] {
  width: 24px; /* Ajustez la largeur selon vos besoins */
  height: 24px; /* Ajustez la hauteur selon vos besoins */
  margin: 8px 0;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: border 0.5s;
  outline: none;
}

input[type=checkbox]:focus {
  border: 2px solid #555; /* Couleur de la bordure au focus */
}

input[type=checkbox]:checked {
  border: 2px solid #555; /* Couleur de la bordure lorsque coché */
  background-color: #555; /* Couleur de fond lorsque coché */
}
