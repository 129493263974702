@keyframes bounce-custom {
  0%, 100% {
    transform: translateY(0); /* Position d'origine */
  }
  50% {
    transform: translateY(-50px); /* Monte vers le haut */
  }
}

.animate-bounce-custom {
  animation: bounce-custom 2s ease-in-out infinite;
}

/* Gestion du décalage */
.delay-0 {
  animation-delay: 0s;
}

.delay-200 {
  animation-delay: 0.2s;
}

.delay-400 {
  animation-delay: 0.4s;
}

/* Animation du texte */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 2s ease-in-out 1;
}
